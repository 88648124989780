import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-bridge'
import { configure } from 'vee-validate'

const validationMessages = {
  en: require('vee-validate/dist/locale/en'),
  th: require('vee-validate/dist/locale/th')
}

Vue.use(VueI18n, { bridge: true })

function loadLocaleMessages() {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {
        ...locales(key),
        validations: validationMessages[locale]
      }
    }
  })
  return messages
}

const i18n = createI18n(
  {
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
  },
  VueI18n
)
Vue.use(i18n)

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`)
    return i18n.t(`validations.messages.${values._rule_}`, values)
  }
})

export default i18n
