import Vue from 'vue'
import request from '@utils/request'
import _ from 'lodash'
import swal, { templates } from '@utils/swal'

Vue.mixin({
  computed: {
    staticAlert() {
      // default alert modal pack
      return {
        success: {
          icon: templates.success.icon,
          title: this.$t(templates.success.title),
          text: this.$t(templates.success.text)
        },
        error: {
          icon: templates.error.icon,
          title: this.$t(templates.error.title),
          text: this.$t(templates.error.text)
        }
      }
    }
  },
  methods: {
    /**
     * request to api with axios
     * @param {Object} options
     *  - disableAlert: to disable alert on response
     *  - disableLoading: to disable loading screen on request
     */
    async $api(options) {
      options.$alert = this.$alert
      if (
        !options.noAuth &&
        (!this.$store.getters.exp ||
          this.$store.getters.exp <= new Date().getTime() / 1000)
      ) {
        await this.$store.dispatch('refreshToken')
        if (!localStorage.getItem('token')) {
          return this.$router.push({ name: 'login' })
        }
      }
      return new Promise((resolve, reject) => {
        request(options)
          .then((response) => {
            if (!options.hideSuccessAlert && !options.hideAlert) {
              this.$alert('success')
            }
            resolve(response)
          })
          .catch((response) => {
            const { status, data } = response
            if (!options.hideErrorAlert && !options.hideAlert) {
              if (status === 400 && data && Object.values(data).length) {
                let msg = Object.values(data)[0]
                _.isArray(msg) && (msg = msg[0])
                this.$alert('custom', {
                  icon: 'error',
                  title: this.$t('alert.validationError'),
                  text: this.$te(`alert.${msg}`) ? this.$t(`alert.${msg}`) : msg
                })
              } else {
                this.$alert('error')
              }
            }
            reject(response)
          })
      })
    },
    /**
     *
     * @param {String} status
     *  - success, error, custom
     * @param {Object} options will use on status is custom
     *  - type
     *  - title
     *  - text
     */
    $alert(status, options = {}) {
      const alert = {
        ...this.staticAlert,
        custom: options
      }

      if (alert[status]) {
        return swal.fire(alert[status])
      }
    },
    async $showDeleteAlert(step) {
      const alertConfigs = {
        1: {
          title: this.$t('alert.submitDelete.title'),
          text: this.$t('alert.submitDelete.text'),
          confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
        },
        2: {
          title: this.$t('alert.submitDelete.warningForSure.title'),
          text: this.$t('alert.submitDelete.warningForSure.text'),
          confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
        },
        3: {
          title: this.$t('alert.submitDelete.confirmAgain.title'),
          text: this.$t('alert.submitDelete.confirmAgain.text'),
          input: 'text',
          confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
        }
      }

      const result = await this.$alert('custom', {
        icon: 'warning',
        showCancelButton: true,
        ...alertConfigs[step]
      })

      return result
    },
    async $confirmDelete(data, deleteFunc, cancelFunc = () => {}) {
      if (!data.length) {
        // minimum select at least one
        return this.$alert('custom', {
          icon: 'warning',
          title: this.$t('alert.submitDelete.warning.title'),
          text: this.$t('alert.submitDelete.warning.text')
        })
      }

      try {
        const step1 = await this.$showDeleteAlert(1)
        if (!step1.value) return cancelFunc(data)

        const step2 = await this.$showDeleteAlert(2)
        if (!step2.value) return cancelFunc(data)

        const step3 = await this.$showDeleteAlert(3)
        if (!step3.isConfirmed) return cancelFunc(data)
        else if (step3.isConfirmed && step3.value !== 'Confirm') {
          this.$alert('custom', {
            icon: 'error',
            title: this.$t('alert.submitDelete.incorrectWord.title')
          }).then(() => {
            cancelFunc(data)
          })
          return
        }

        await deleteFunc(data)

        await this.$alert('custom', {
          icon: 'success',
          title: this.$t('alert.submitDelete.done.title'),
          text: this.$t('alert.submitDelete.done.text')
        })
      } catch (error) {
        cancelFunc(data)
      }
    },
    $accountingFormat(value) {
      if (value < 0) {
        const formatted = `(${Math.round((value * 100) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
        return formatted
      } else {
        const formatted = Math.round((value * 100) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return formatted
      }
    },
    $sumField(items, key) {
      // Sum data in the given key (property)
      return items.reduce(
        (a, b) => parseFloat(a) + parseFloat(b[key] || 0),
        parseFloat(0)
      )
    }
  }
})
