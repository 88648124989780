import Swal from 'sweetalert2'
import _ from 'lodash'

const swalBtnClass = 'v-btn v-btn--has-bg theme--light v-size--default mx-4'
const swal = Swal.mixin({
  target: '#app',
  customClass: {
    confirmButton: `${swalBtnClass} primary`,
    cancelButton: `${swalBtnClass} error`,
    input: 'swal2-input-popup-borderless'
  },
  buttonsStyling: false
})

export const templates = {
  success: {
    icon: 'success',
    title: 'alert.success.title',
    text: 'alert.success.text'
  },
  error: {
    icon: 'error',
    title: 'alert.error.title',
    text: 'alert.error.text'
  }
}

export const swalError = (t, text) => {
  swal.fire({
    icon: 'error',
    title: t(templates.error.title),
    text: text ?? t(templates.error.text)
  })
}

export const swalValidationError = (t, data) => {
  let txt = ''
  _.forEach(data, (value, key) => {
    if (_.isArray(value)) {
      value.forEach((msg) => {
        txt += `${key}: ${msg}\n`
      })
    }
  })
  swal.fire({
    icon: 'error',
    title: t('alert.validationError'),
    text: txt
  })
}

export const swalRequestErrorHandler = (t, error) => {
  const { status, data } = error
  if (status === 400 && data && Object.values(data).length) {
    swalValidationError(t, data)
  } else {
    swalError(t)
  }
}

export const swalSuccess = (t, text) => {
  swal.fire({
    icon: 'success',
    title: t('alert.success.title'),
    text: text ?? t('alert.success.text')
  })
}

export default swal
